<div class="change-container">
  <div class="forget-card">
    <div class="card-header">
      <div class="d-flex justify-content-start">
        <img class="logo" src="/images/nutricia-logo.png" alt="profile" />
      </div>
      <div class="justify-content-start">
        <span class="card-title">
          {{ "CHANGE_PASSWORD.TITLE" | translate }}</span
        >
        <br /><span class="card-subtitle">{{
          "CHANGE_PASSWORD.SUBTITLE" | translate
        }}</span>
      </div>
    </div>
    <div class="forget-body">
      <form [formGroup]="changePasswordForm">
        <div class="col-12 form-group position-relative">
          <label class="form-label form-field" for="exampleInputEmail1">
            {{ "RESET.CURRENT_PASSWORD" | translate
            }}<span class="text-danger"> *</span></label
          >
          <input
            formControlName="currentPassword"
            [type]="hideCurrentPassword ? 'password' : 'text'"
            class="form-control"
            name="password"
            aria-describedby="emailHelp"
          />
          <button
            type="button"
            class="password-toggle-icon"
            (click)="hideCurrentPassword = !hideCurrentPassword"
          >
            <mat-icon>{{
              hideCurrentPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
          <small
            *ngIf="
              (changePasswordForm.get('currentPassword')?.invalid &&
                changePasswordForm.get('currentPassword')?.touched) ||
              submitted
            "
          >
            <small
              class="text-danger"
              *ngIf="
                changePasswordForm.get('currentPassword')?.errors?.['required']
              "
            >
              {{ "RESET.CURRENT_PASSWORDR" | translate }}
            </small>
          </small>
        </div>
        <div class="col-12 form-group position-relative mt-3">
          <label class="form-label form-field" for="exampleInputEmail1">
            {{ "RESET.NEWPASSWORD" | translate
            }}<span class="text-danger"> *</span></label
          >
          <input
            formControlName="newPassword"
            [type]="hideNewPassword ? 'password' : 'text'"
            class="form-control"
            name="password"
            aria-describedby="emailHelp"
          />
          <button
            type="button"
            class="password-toggle-icon"
            (click)="hideNewPassword = !hideNewPassword"
          >
            <mat-icon>{{
              hideNewPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
          <small
            *ngIf="
              (changePasswordForm.get('newPassword')?.invalid &&
                changePasswordForm.get('newPassword')?.touched) ||
              submitted
            "
          >
            <small
              class="text-danger"
              *ngIf="
                changePasswordForm.get('newPassword')?.errors?.['required']
              "
            >
              {{ "RESET.NEWPASSWORDR" | translate }}
            </small>
          </small>
        </div>
        <div class="col-12 form-group position-relative mt-3">
          <label class="form-label form-field" for="exampleInputEmail1">
            {{ "RESET.CONFIRMNEWPASSOWRD" | translate
            }}<span class="text-danger"> *</span></label
          >
          <input
            formControlName="confirmNewPassword"
            [type]="hideConfirmPassword ? 'password' : 'text'"
            class="form-control"
            name="confirmNewPassword"
            aria-describedby="emailHelp"
          />
          <button
            type="button"
            class="password-toggle-icon"
            (click)="hideConfirmPassword = !hideConfirmPassword"
          >
            <mat-icon>{{
              hideConfirmPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
          <small
            *ngIf="
              (changePasswordForm.get('confirmNewPassword')?.invalid &&
                changePasswordForm.get('confirmNewPassword')?.touched) ||
              submitted
            "
          >
            <small
              class="text-danger"
              *ngIf="
                changePasswordForm.get('confirmNewPassword')?.errors?.[
                  'required'
                ]
              "
            >
              {{ "RESET.CONFIRMPASSWORDR" | translate }}
            </small>
            <small
              class="text-danger"
              *ngIf="
                changePasswordForm.get('confirmNewPassword')?.value &&
                changePasswordForm.get('newPassword')?.value &&
                changePasswordForm.get('confirmNewPassword')?.value !==
                  changePasswordForm.get('newPassword')?.value
              "
            >
              {{ "RESET.PASSWORD_NOT_MATCH" | translate }}
            </small>
          </small>
        </div>
      </form>
    </div>
    <div class="forget-card-footer d-flex justify-content-between mt-4">
      <div>
        <button
          type="submit"
          (click)="goToCurrentPath()"
          class="btn btn-primary cancel-btn"
        >
          {{ "BUTTON.CANCEL" | translate }}
        </button>
      </div>
      <div>
        <button
          type="submit"
          class="btn btn-primary submit-btn"
          (click)="submit()"
          [disabled]="changePasswordForm.invalid"
        >
          {{ "BUTTON.SUBMIT" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
