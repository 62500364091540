import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationServiceProxy } from '../../../../services/nhost-api-service';
import { LoaderService } from '../../../../core/services/loader.service';
import { catchError, of, Subject, takeUntil, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../../core/services/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss',
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  changePasswordForm: FormGroup = new FormGroup({});
  submitted: boolean = false;
  hideCurrentPassword: boolean = true;
  hideNewPassword: boolean = true;
  hideConfirmPassword: boolean = true;
  onDestroy$: Subject<void> = new Subject<void>();
  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private userService: UserService,
    private loginService: AuthenticationServiceProxy,
    private router: Router
  ) {}
  ngOnInit() {
    this.changePasswordForm = this.fb.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmNewPassword: ['', [Validators.required]],
    });
  }
  submit() {
    this.submitted = true;
    if (
      this.changePasswordForm.value.currentPassword ===
      this.changePasswordForm.value.confirmNewPassword
    ) {
      this.toastr.error(
        this.translate.instant('TOAST.SAME_PASSWORD'),
        this.translate.instant('TOAST.ERROR')
      );
      return;
    }

    if (
      this.changePasswordForm.value.newPassword !==
      this.changePasswordForm.value.confirmNewPassword
    )
      return;
    const request: any = {
      emailAddress: localStorage.getItem('emailAddress'),
      oldPassword: this.changePasswordForm.value.currentPassword,
      newPassword: this.changePasswordForm.value.newPassword,
    };
    this.loaderService.hideShowLoader(true);
    this.loginService
      .changePassword(request)
      .pipe(
        takeUntil(this.onDestroy$),
        tap(() => {
          this.toastr.success(
            this.translate.instant('TOAST.PWDRESET'),
            this.translate.instant('TOAST.SUCCESS')
          );
          this.router.navigate(['/auth']);
          this.loaderService.hideShowLoader(false);
        }),
        catchError(() => {
          this.toastr.error(
            this.translate.instant('TOAST.INVALID_OLD_PASSWORD'),
            this.translate.instant('TOAST.ERROR')
          );
          this.loaderService.hideShowLoader(false);
          return of(null);
        })
      )
      .subscribe();
  }
  goToCurrentPath() {
    this.userService.updateUser();
    this.router.navigate([localStorage.getItem('currentPath')]);
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
