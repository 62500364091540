import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// import { AuthenticationServiceProxy } from '../../../../services/nhost-api-service';
import { catchError, of, Subject, takeUntil, tap } from 'rxjs';
import { LoaderService } from '../../../../core/services/loader.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationServiceProxy } from '../../../../services/nhost-api-service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrl: './forget-password.component.scss',
})
export class ForgetPasswordComponent implements OnInit, OnDestroy {
  submitted: boolean = false;
  forgetForm: FormGroup = new FormGroup({});
  isForget: boolean = true;
  onDestroy$: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private loaderService: LoaderService,
    private toastr: ToastrService,
    private loginService: AuthenticationServiceProxy,
    private translate: TranslateService,
  ) {}
  ngOnInit(): void {
    this.forgetForm = this.fb.group({
      emailAddress: ['', [Validators.required, Validators.email]],
    });
  }

  submit() {
    this.submitted = true;
    this.loaderService.hideShowLoader(true);
    this.loginService
      .forgetPassword(this.forgetForm.value)
      .pipe(
        takeUntil(this.onDestroy$),
        tap(() => {
          this.loaderService.hideShowLoader(false);
          this.isForget = false;
        }),
        catchError((error: any) => {
          const message = this.translate.instant('TOAST.INVALID_EMAIL');
          const toastrSuccess = this.translate.instant('TOAST.ERROR');
          this.toastr.error(message, toastrSuccess);
          this.loaderService.hideShowLoader(false);
          return of(null);
        }),
      )
      .subscribe();
  }
  cancel() {
    localStorage.removeItem('token');
    this.router.navigate(['/auth']);
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
